import React, {useEffect, useState} from 'react';
import CampaignSelector from './CampaignSelector';
import HeadlineSelector from './HeadlineSelector';
import ImageSelector from './ImageSelector';
import SubmitButton from './SubmitButton';
import {CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Stepper, Step, StepLabel, StepContent, Paper} from '@mui/material';
import {jwtDecode as jwt_decode} from 'jwt-decode';
import MatrixGridComponent from "./MatrixGridComponent";
import {AccountCircle, Logout, ContentCopy as ContentCopyIcon, ArrowForward, ArrowBack, VisibilityOutlined, InfoOutlined} from "@mui/icons-material";
import {
    Alert,
    Box, Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Chip,
    Card,
    CardContent,
    Divider,
    useTheme,
    Fade
} from "@mui/material";
import axios from "axios";
import io from 'socket.io-client';
import MidJourneyStyleSelector from "./MidjourneyStyleSelector";
import InstructionsComponent from "./InstructionsComponent";
import logo from '../images/typ.png'
import S3Uploader from "./s3";
import LogViewer from "./Logviewer";
import TaboolaAccounts from "./TaboolaAccounts";
import {useNavigate} from "react-router-dom";
import Logo from "./Logo";
import KeywordsInput from "./Keywords";
import LanguageSelector from "./LanguageSelector";
import CountriesList from "./CountriesList";
import CampaignsTable from "./CampaignsTable";
import Layout from "./Layout";
import SpyAdsComponent from "./SpyAdsComponent";
import {object} from "promisify";
import FeedSelector from './FeedSelector';
import CampaignPreview from './CampaignPreview';
import config from '../config';

import {useParams} from 'react-router-dom';


function Creation() {
    const navigate = useNavigate();
    const theme = useTheme();

    const {id} = useParams();
    const [campaign, setCampaign] = useState('');
    const [campaigns, setCampaigns] = useState([]);

    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingClone, setLoadingClone] = useState(false);

    const [loadingHeadlines, setLoadingHeadlines] = useState(false);
    const [loadingImagePrompt, setLoadingImagePrompt] = useState(false);
    const [taboolaAccount, setTaboolaAccount] = useState('');
    const [language, setLanguage] = useState('English');
    const [loadingRecalculateHeadlines, setLoadingRecalculateHeadlines] = useState(false);


    const [selectedInstructionHeadlines, setSelectedInstructionHeadlines] = useState('');


    const [loadingCreateCampaign, setLoadingCreateCampaign] = useState(false);

    const [loadingImages, setLoadingImages] = useState(false);
    const [CPAGoal, setCPAGoal] = useState("1");
    const [countries, setCountries] = useState([]);

    const [logMessages, setLogMessages] = useState([]);
    const [spyAds, setSpyAds] = useState([]);
    const [startDone, setStartDone] = useState(false);

    const [keywords, setKeywords] = useState(Array(5).fill(''));
    const [keyword, setKeyword] = useState('');


    // Function to add a new log message
    const addLogMessage = (message) => {
        const now = new Date();
        const timestamp = now.toISOString().split('T')[1].slice(0, 8); // Format: HH:MM:SS
        const timedMessage = `${timestamp} - ${message}`;
        setLogMessages(prevMessages => [...prevMessages, timedMessage]);
    };

    const [selectedHeadlineGuideline, setSelectedHeadlineGuideline] = useState('');
    const [headlines, setHeadlines] = useState([]);
    const [selectedHeadlines, setSelectedHeadlines] = useState(new Set());

    const [offers, setOffers] = useState([]);

    const [description, setDescription] = useState('');
    const [imagePrompt, setImagePrompt] = useState('');
    const [selectedCards, setSelectedCards] = useState([]);
    const [campaignTitle, setCampaignTitle] = useState('');
    const [campaignBudget, setCampaignBudget] = useState(20);
    const [campaignDevice, setCampaignDevice] = useState(['PHON']);
    const [campaignType, setCampaignType] = useState('RSOC'); // Default to RSOC
    const [topic, setTopic] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const [showVariations, setShowVariations] = useState(false);
    const [loadingStart, setLoadingStart] = useState(false);
    const [selectedStyles, setSelectedStyles] = useState([]);
    const [user, setUser] = useState(null); // State to store the user's data

    const [openDialog, setOpenDialog] = useState(false);

    const [selectedFeed, setSelectedFeed] = useState('');
    const [feeds, setFeeds] = useState([]);
    const [feedContent, setFeedContent] = useState(null);
    const [loadingFeedContent, setLoadingFeedContent] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);
    const [feedUrl, setFeedUrl] = useState('');

    const [activeStep, setActiveStep] = useState(0);


    // Function to update state based on user input
    const handleTitleChange = (event) => {
        setCampaignTitle(event.target.value);
    };

    // Function to update keyword and modify URL with {q} parameter
    const handleKeywordChange = (event) => {
        const newKeyword = event.target.value;
        setKeyword(newKeyword);
        
        // If we have a feed selected, update its URL with the new keyword
        if (selectedFeed && feeds.length > 0) {
            const feed = feeds.find(f => f.id === selectedFeed);
            if (feed && feed.url) {
                // Create a new URL with the keyword replacing {q} parameter
                const updatedUrl = feed.url.replace(/{q}/g, newKeyword.replace(/\s/g, '+'));
                setFeedUrl(updatedUrl);
            }
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                // Show success message briefly
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 2000);
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const [images, setImages] = useState([]);



    useEffect(() => {
        setCampaignTitle(generateCampaignName);
        console.log(user)


    }, [topic, campaign]);

    useEffect(() => {
        // When a feed is selected, fetch its content
        if (selectedFeed) {
            const feed = feeds.find(f => f.id === selectedFeed);
            if (feed) {
                fetchFeedContent(selectedFeed);
                
                // Initialize the URL with the current keyword
                if (feed.url && keyword) {
                    const updatedUrl = feed.url.replace(/{q}/g, keyword.replace(/\s/g, '+'));
                    setFeedUrl(updatedUrl);
                } else if (feed.url) {
                    setFeedUrl(feed.url);
                }
            }
        }
    }, [selectedFeed, feeds]);

    const fetchFeedContent = async (feedId) => {
        try {
            setLoadingFeedContent(true);
            const apiUrl = `${config.API_URL}/feeds/${feedId}/content`;
            console.log('Fetching feed content from:', apiUrl);
            const response = await axios.get(apiUrl);
            console.log('Feed content response:', response);
            setFeedContent(response.data);
            
            // We no longer automatically set the keyword from feed content
        } catch (error) {
            console.error('Error fetching feed content:', error);
        } finally {
            setLoadingFeedContent(false);
        }
    };


    const getMacros = (provider) => {
        const formattedString = keywords.map((keyword, index) => `kw${index + 1}=${keyword.replace(/\s/g, '%20')}`).join('&');
        const keys = ['forceKeyA', 'forceKeyB', 'forceKeyC', 'forceKeyD', 'forceKeyE'];
        const system1Keywords = keywords.slice(0, 5).map((keyword, index) => `${keys[index]}=${keyword.replace(/\s/g, '%20')}`).join('&');
        switch (provider) {
            case 'Tonic.com':
                return `?subid4={cf_click_id}&click_id={external_id}&network={traffic_source_name}&site={trackingField1}&siteid={trackingField2}&adtitle={trackingField3}&subid2={trackingField5}&${formattedString}`
            case 'Ads.com':
                return `?ref_adnetwork=Taboola&ref_pubsite={trackingField1}&ref_keyword={trackingField3}&subid1={cf_click_id}&terms=${keywords.join(',')}`
            case 'System1':
                return `?ref=taboola-{trackingField1}&sub_id={cf_click_id}&sub_id2={trackingField8}|{trackingField3}&rskey={trackingField3}&${system1Keywords}&impression_track_url=https%3A%2F%2Fsearchgw.com%2Fcf%2Fcv%3Fclick_id%3D%7Bcf_click_id%7D%26payout%3D0%26ct%3Dland&search_track_url=https%3A%2F%2Fsearchgw.com%2Fcf%2Fcv%3Fclick_id%3D%7Bcf_click_id%7D%26payout%3D0%26ct%3Dsearch&click_track_url=https%3A%2F%2Fsearchgw.com%2Fcf%2Fcv%3Fclick_id%3D%7Bcf_click_id%7D%26payout%3D0%26ct%3Dad_click`
            case 'Sedo.com':
                return `?sub1={cf_click_id}&click_id={external_id}&network=taboola&site={trackingField2}_{trackingField1}&adtitle={trackingField3}&kwhl=en&${formattedString}`
            case 'promo-search':
                return `?q=${topic.replace(/\s/g, '+')}&tid=4088&click_id={cf_click_id}`
            default:
                return ""
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwt_decode(token);
            setUser(decodedToken);
        }

    }, []);


    function generateCampaignName() {
        const now = new Date();

        // Convert the date to the desired format if it's not a string already
        const formattedDate = new Date(now).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        // Return the concatenated string
        return `${user?.username.toUpperCase()} - ${keyword?.replace(/\s/g, '_').toLowerCase()} - ${formattedDate}`;
    }


    const fetchHeadlines = async (spyAdsResponse) => {

        try {

            const instructions =
                'Rule 1) Whenever a city, country, or region is mentioned in a headline, replace it with the corresponding macro. Use "${city:capitalized}$" for cities, "${country:capitalized}$" for countries, and "${region:capitalized}$" for regions.\n' +
                'RULE 2) Ensure that each revised headline is unique and avoid repeating any headline.' +
                'RULE 3) Max 130 characters.' +
                'RULE 4) We are at year 2025, so change any year for 2025 if it is mentioned in the headline.' +
                'Rule 5) Whenever is possible, use call to actions between "()" like "(See More)", "(Search Here)", "(Find Results)", etc'


            const titles = spyAdsResponse.map(ad => ad.title);
            console.log('titles', titles.length)
            const uniqueTitles = Array.from(new Set(titles));
            console.log('Unique Titles:', uniqueTitles.length);


            const prompt = `
    Return 10 headlines in JSON format: headlines: ['','','']
    The first five headlines should be selected from the provided list and adapt them to the keyword: '${keyword}', 
    applying these rules: ${instructions}. 
    List: "${uniqueTitles.join(',')}".
    The remaining five should be newly created, 
    relevant to the keyword: '${keyword}', 
    and meticulously follow the tone, syntax, grammar, and meaning 
    of the provided list.  
    Language of the headlines should be ${language}.
`;

            const response = await axios.post(`${config.API_URL}/openAI/sendChat`, {prompt: prompt});
            return JSON.parse(response.data.response);
        } catch (error) {
            console.error('Error fetching headlines:', error);
            return [];
        } finally {

        }
    };


    const fetchSpyAds = async () => {
        const response = await axios.get(
            `${config.API_URL}/spy?topic=${topic}`,
        );

        console.log(response.data)
        const creativeData = response.data
        const ads = Object.values(creativeData).map(ad => ({
            title: ad.title,
            imageName: ad.thumbnail,
            keywords: ad.keywords
        }));
        console.log(ads)

        await setSpyAds(ads);

        return ads
    }


    const generateImagePrompt = async () => {
        try {

            console.log(spyAds)

            const imageUrl = spyAds[0].imageName
            const response = await axios.post(`${config.API_URL}/openAI/vision`, {imageUrl});
            setImagePrompt(response.data.response.message.content);


        } catch (error) {
            // Consider more specific error handling based on the error response
            console.error('Error fetching images:', error);
            return [];
        } finally {
            setLoading(false);
        }
    }

    const fetchDescription = async (_headlines) => {

        try {
            console.log('headlines', _headlines)
            const prompt = `Craft a universally appealing, concise description (max 150 characters) for native advertising, drawing from the essence of the following headlines: ${_headlines}
            The description should be catchy, smoothly integrate with content, and be applicable to a broad audience without any locale-specific references or placeholder mentions. It should entice readers organically without feeling like a traditional advertisement. Don't be exaggerated or overpromise.
            Use Language: ${language}
            Return it as a json { description: ''}`
            const response = await axios.post(`${config.API_URL}/openAI/sendChat`, {prompt});
            return JSON.parse(response.data.response);
        } catch (error) {
            console.error('Error fetching headlines:', error);
            return [];
        } finally {

        }
    };


    useEffect(() => {

        if (campaign.country) setCountries([campaign.country])

    }, [campaign]);


    const fetchImages = async () => {
        setLoadingImages(true);


        try {
            let result = spyAds.map(ad => ad.imageName);
            setImages(result)
            let imagesCount = 0;
            let modifiedImagePrompt = imagePrompt.replaceAll('${topic}', topic);
            modifiedImagePrompt = modifiedImagePrompt.replaceAll('${titles}', selectedHeadlines);

            const socket = io(config.API_URL);

            let resolvePromise, rejectPromise;

            // Set up event listeners
            socket.on('progressUpdate', message => {
                console.log('Progress Update:', message);
                addLogMessage(message);
            });

            socket.on('imageGenerated', (data) => {
                console.log(data);
                if (data.urls) {
                    result = [...result, ...data.urls];
                    imagesCount += data.urls.length;
                }
                setImages(result);
                if (imagesCount >= selectedStyles.length * 4) {
                    socket.disconnect();
                    resolvePromise(result); // Resolve the promise when all images are received
                }
            });

            socket.on('error', (errorMessage) => {
                console.error('error', errorMessage);
                socket.disconnect();
                rejectPromise(new Error(errorMessage)); // Reject the promise on error
            });

            // Emit events for each style
            selectedStyles.forEach((style) => {
                socket.emit('generateImage', {'imagePrompt': modifiedImagePrompt, style});
            });

            // Create a promise to wait for images or timeout
            await new Promise((resolve, reject) => {
                resolvePromise = resolve; // Assign resolve function
                rejectPromise = reject;   // Assign reject function

                const timeoutId = setTimeout(() => {
                    socket.disconnect();
                    reject(new Error("Timeout: Images could not be fetched in 2 minutes"));
                }, 120000); // 2 minutes timeout

                // Clear the timeout when the promise is resolved or rejected
                const clearTimer = () => clearTimeout(timeoutId);
                resolvePromise = resolvePromise.bind(null, clearTimer);
                rejectPromise = rejectPromise.bind(null, clearTimer);
            });
        } catch (error) {
            console.error('Error fetching images:', error);
            return [];
        } finally {
            setLoadingImages(false);
            setLogMessages([])
        }
    }


    const handleGenerateImagePrompt = async () => {
        setLoadingImagePrompt(true);
        await generateImagePrompt();
        setLoadingImagePrompt(false);
    }

    const createTask = async () => {

        let data = [];
        console.log(user)
        for (const country of countries) {
            for (const device of campaignDevice) {

                let campaignName = campaignTitle.replaceAll('{country}', country)
                campaignName = campaignName.replaceAll('{device}', device)
                data.push({
                    data: {
                        'campaignName': campaignName,
                        'brandingText': 'Search Ads',
                        'country': country,
                        'device': device,
                        'instructionsHeadlines': 1,
                        'instructionsImages': 1,
                        'styles': 'all',
                        'budget': campaignBudget,
                        'feedId': selectedFeed,
                        'keyword': keyword,
                        'offers': offers,
                        'offerName': campaignName,
                        'ads': selectedCards,
                        'user': user.userId,
                        'campaignType': campaignType
                    }, status: 'pending', userId: user.userId
                })
            }
        }

        try {
            // Send a POST request to the /tasks route with the data
            console.log('data to send: ', data);
            const response = await axios.post(`${config.API_URL}/tasks`, data);
            console.log(response.data); // Log the response data
        } catch (error) {
            console.error('Error inserting tasks:', error);
        }

        return data;

    }


    const handleCreateCampaigns = async (event) => {
        event.preventDefault();
        
        // Check if a feed is selected
        if (!selectedFeed) {
            setError('Please select a feed before creating campaigns');
            return;
        }
        
        // Find the selected feed object to validate its account_id
        const selectedFeedObj = feeds.find(f => f.id === selectedFeed);
        
        // Check if the feed has a valid account_id
        if (!selectedFeedObj || !selectedFeedObj.account_id) {
            setError('The selected feed does not have a valid account ID. Please edit the feed to add an account ID before creating campaigns.');
            return;
        }
        
        try {
            setLoadingCreateCampaign(true);
            const result = await createTask();
            setLoadingCreateCampaign(false);
            
            // Redirect to the tasks page (which is now the root path) with success state
            const taskCount = result.length || 0;
            const adCount = selectedCards.length * countries.length * campaignDevice.length;
            navigate('/', { 
                state: { 
                    success: true, 
                    message: `Successfully created ${taskCount} campaigns with ${adCount} ads!`,
                    fromCreation: true,
                    timestamp: new Date().getTime() // Add timestamp to ensure the message shows even if they've been to tasks before
                }
            });
        } catch (error) {
            console.error('Error during campaign creation:', error);
            setLoadingCreateCampaign(false);
            setError('Failed to create campaigns. Please try again.');
        }
    }

    const createCampaign = async (country) => {
        const createCampaignEndpoint = `${config.API_URL}/campaigns/create`;
        try {
            let campaignName = campaignTitle.replaceAll('{country}', country)
            let brandingText = 'Search Ads'
            let device = campaignDevice
            let budget = campaignBudget
            let offerName = topic

            console.log(campaignName, brandingText, country, device, budget, offerName, selectedFeed)
            // Generate the prompt for the image
            const result = await axios.post(createCampaignEndpoint, {
                campaignName, 
                brandingText, 
                country, 
                device, 
                budget, 
                feedId: selectedFeed, 
                keyword, // Add keyword for URL processing
                offerName
            });


            return result;
        } catch (error) {
            // Consider more specific error handling based on the error response
            console.error('Error Creating Campaign:', error);
            return [];
        } finally {
        }
    }

    const createAds = async (taboolaCampaignId) => {
        try {
            let ads = selectedCards

            const adsCreated = await new Promise((resolve, reject) => {
                const socket = io(config.API_URL);
                console.log('Socket adsCreated')

                socket.emit('createAds', {taboolaCampaignId, feedId: selectedFeed, ads});

                socket.on('adCreationProgress', (data) => {
                    console.log(data.message);
                    addLogMessage(data.message);
                    // Update the UI with progress
                });

                socket.on('adsCreated', (data) => {
                    console.log(data);
                    socket.disconnect();
                    resolve(data);
                });

                socket.on('error', (errorMessage) => {
                    console.log('error', errorMessage);
                    socket.disconnect();
                    reject(new Error(errorMessage));
                });
            });

            // Validate the images response here if necessary
            return adsCreated;


        } catch (error) {
            // Consider more specific error handling based on the error response
            console.error('Error Creating Campaign:', error);
            return [];
        } finally {
        }
    }


    const onSubmit = async (event) => {

        event.preventDefault();
        console.log('onSubmit')
        setLoading(true)
        setHeadlines([])
        setImages([])
        let fetchedHeadlines = await fetchHeadlines(selectedHeadlineGuideline);
        setHeadlines(fetchedHeadlines.headlines);

        setDescription("Search for "+ keyword);

        await generateImagePrompt();
        setLoading(false)
    }

    const handleGenerateHeadlines = async () => {
        setLoadingHeadlines(true)
        setHeadlines([])

        console.log(selectedHeadlineGuideline, selectedInstructionHeadlines)
        console.log("guidelines headlines:", selectedInstructionHeadlines)
        let fetchedHeadlines = await fetchHeadlines();
        setHeadlines(fetchedHeadlines.headlines);

        setLoadingHeadlines(false)
    }

    const handleStart = async (event) => {

        event.preventDefault()
        setLoadingStart(true)
        console.log('handleStart')
        const spyAdsResponse = await fetchSpyAds();
        const spyImages = spyAdsResponse.map(ad => ad.imageName);
        setImages(spyImages)

        let allSpyKeywords = spyAdsResponse.map(item => item.keywords).flat();
        allSpyKeywords = allSpyKeywords.filter(item => item !== undefined)


        console.log('allSpyKeywords', allSpyKeywords)

        const fetchedHeadlines = await fetchHeadlines(spyAdsResponse);
        setHeadlines(fetchedHeadlines.headlines);


        setDescription('Search for ' + keyword);
        setLoadingStart(false)
        setStartDone(true)
        
        // Automatically advance to the next step
        handleNext()
    }

    const fetchKeywords = async (headlines) => {


        console.log('headlines fetchKeywords', headlines)

        let prompt = ''

        prompt = `based on the following keywords:

Cheap Storage Units
Cheap Storage Units Near Me
Portable Storage Containers
Storage Units Prices
Affordable Storage Options In {city}
Self-storage Units In {city}
House Foundation Repair
foundation repair near me
foundation repair cost 2022
foundation crack repair
foundation specialist near me
liposuction
weight loss injections
laser fat removal near me
laser fat removal cost
liposuction How much does it cost


Generate 5 similar style keywords for the topic: ${topic}, focus on the keywords with higher earnings per click. Use Language: ${language} Return it in json format: keywords: ['','','', ...]`


        if (headlines.length > 0) {
            prompt = `Return 5 titles from this list,
             exclude repeated,
             and whenever you see a city, replace it for the macro "{city}.
             If the list is less than 5, create the remaining headlines based on the topic: "${topic}", ensuring they match the tone, the meaning, grammar, and vocabulary of those already listed.
             Rule 1) Limit headlines to a maximum of 3-6 words. Condense content significantly, omitting articles if necessary, while retaining key meaningful words.
             Here the list: "${headlines.join(",")}".
             Return it in json format: keywords: ['','','', ...]"`


        }
        try {
            const response = await axios.post(`${config.API_URL}/openAI/sendChat`, {prompt});


            console.log(response.data.response)

            return JSON.parse(response.data.response);
        } catch (error) {
            console.error('Error fetching keywords:', error);
            return [];
        } finally {

        }
    };

    const handleRecalculateHeadlines = async () => {
        setLoadingRecalculateHeadlines(true)
        const fetchedHeadlines = await fetchHeadlines(spyAds);
        setHeadlines(prevHeadlines => {
            // Combine previous headlines with new ones, converting to a Set to remove duplicates, then back to an array
            const combinedUniqueHeadlines = [...new Set([...selectedHeadlines, ...fetchedHeadlines.headlines])];
            return combinedUniqueHeadlines;
        });
        setLoadingRecalculateHeadlines(false)
    }

    const handleRecalculateKeywords = async () => {
        const recalculateKeywords = await fetchKeywords([...selectedHeadlines]);
        setKeywords(recalculateKeywords.keywords)
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // Define steps for the wizard
    const steps = [
        {
            label: 'Campaign Setup',
            description: 'Configure basic campaign settings',
            icon: '1'
        },
        {
            label: 'Creative Assets',
            description: 'Select headlines and images',
            icon: '2'
        },
        {
            label: 'Ad Matrix',
            description: 'Create ad combinations',
            icon: '3'
        },
        {
            label: 'Launch',
            description: '',
            icon: '4'
        },
    ];

    if (error) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh',
                    p: 2,
                    gap: 2,
                    textAlign: 'center',
                }}
            >
                <Typography variant="h6" component="h2" color="error">
                    {error}
                </Typography>
                <Button variant="outlined" onClick={() => setError(null)}>
                    Try Again
                </Button>
            </Box>
        );
    }

    if (loadingClone) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh',
                    p: 2,
                    gap: 3,
                    textAlign: 'center',
                }}
            >
                <CircularProgress size={40} />
                <Typography variant="h6" component="h2" color="primary">
                    Loading campaign configuration
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Just a moment while we prepare everything...
                </Typography>
            </Box>
        );
    }

    return (
        <Layout>
            <Container maxWidth="md" sx={{ py: 4 }}>
                {/* Stepper */}
                <Card elevation={0} sx={{ mb: 4, borderRadius: 2, bgcolor: 'background.paper' }}>
                    <CardContent sx={{ px: { xs: 2, sm: 3 }, py: 2.5 }}>
                        <Stepper 
                            activeStep={activeStep} 
                            alternativeLabel
                            sx={{ 
                                '& .MuiStepConnector-line': {
                                    borderColor: theme.palette.divider
                                }
                            }}
                        >
                            {steps.map((step, index) => (
                                <Step 
                                    key={step.label}
                                    completed={index < activeStep}
                                    sx={{ 
                                        cursor: startDone && index < activeStep ? 'pointer' : 'default',
                                    }}
                                    onClick={() => startDone && index < activeStep && setActiveStep(index)}
                                >
                                    <StepLabel>
                                        <Typography 
                                            variant="subtitle2" 
                                            color={index === activeStep ? 'primary' : 'text.secondary'}
                                        >
                                            {step.label}
                                        </Typography>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        
                        <Typography 
                            variant="body2" 
                            color="text.secondary" 
                            align="center" 
                            sx={{ 
                                mt: 1,
                                fontWeight: 400,
                                fontSize: '0.875rem'
                            }}
                        >
                            {steps[activeStep].description}
                        </Typography>
                    </CardContent>
                </Card>

                <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth={true} maxWidth="lg">
                    <DialogTitle>
                        Inspiration for: {topic}
                        <IconButton 
                            aria-label="close" 
                            onClick={() => setOpenDialog(false)} 
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            &times;
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <SpyAdsComponent topic={topic}/>
                    </DialogContent>
                </Dialog>

                {/* Step 1: Campaign Setup */}
                <Fade in={activeStep === 0} timeout={300}>
                    <div style={{ display: activeStep === 0 ? 'block' : 'none' }}>
                        <Card sx={{ mb: 4, borderRadius: 2 }}>
                            <CardContent sx={{ px: { xs: 2, sm: 3 }, pt: 2, pb: 3 }}>
                                <form onSubmit={handleStart}>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                                            Feed Selection
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        
                                        <FeedSelector 
                                            selectedFeed={selectedFeed} 
                                            onFeedChange={(feedId) => {
                                                setSelectedFeed(feedId);
                                            }}
                                            onFeedsLoaded={(loadedFeeds) => {
                                                setFeeds(loadedFeeds);
                                            }}
                                        />
                                    </Box>
                                    
                                    {selectedFeed && feeds.length > 0 && (
                                        <Card variant="outlined" sx={{ mb: 3, bgcolor: 'background.default', borderRadius: 1 }}>
                                            <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1.5 }}>
                                                    <Typography variant="subtitle2">
                                                        Feed Details
                                                    </Typography>
                                                    {(() => {
                                                        const feed = feeds.find(f => f.id === selectedFeed);
                                                        return feed && (
                                                            <Chip 
                                                                label={feed.status} 
                                                                size="small" 
                                                                color={feed.status === 'active' ? 'success' : 'default'}
                                                                variant="outlined"
                                                            />
                                                        );
                                                    })()}
                                                </Box>
                                                
                                                {(() => {
                                                    const feed = feeds.find(f => f.id === selectedFeed);
                                                    return feed ? (
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6}>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    <strong>Name:</strong> {feed.name}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    <strong>Type:</strong> {feed.type}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    <strong>Source:</strong> {feed.traffic_source || 'taboola'}
                                                                </Typography>
                                                                {feed.account_id ? (
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        <strong>Account ID:</strong> {feed.account_id}
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography variant="body2" color="error">
                                                                        <strong>Account ID:</strong> Not set
                                                                    </Typography>
                                                                )}
                                                            </Grid>
                                                            
                                                            {!feed.account_id && (
                                                                <Grid item xs={12}>
                                                                    <Alert severity="warning" sx={{ mt: 1, py: 0.5 }}>
                                                                        Add an account ID before creating campaigns
                                                                    </Alert>
                                                                </Grid>
                                                            )}
                                                            
                                                            <Grid item xs={12}>
                                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                                                                    <Typography variant="body2" color="text.secondary" sx={{ flexShrink: 0 }}>
                                                                        <strong>URL:</strong>
                                                                    </Typography>
                                                                    <Tooltip title={copySuccess ? "Copied!" : "Copy URL"}>
                                                                        <IconButton 
                                                                            size="small" 
                                                                            onClick={() => copyToClipboard(feed.url)}
                                                                            sx={{ ml: 1 }}
                                                                            color={copySuccess ? "success" : "default"}
                                                                        >
                                                                            <ContentCopyIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>
                                                                <Paper 
                                                                    variant="outlined" 
                                                                    sx={{ 
                                                                        p: 1.5, 
                                                                        mt: 0.5, 
                                                                        backgroundColor: '#fafafa',
                                                                        fontFamily: 'monospace',
                                                                        fontSize: '0.75rem',
                                                                        maxHeight: '80px',
                                                                        overflow: 'auto',
                                                                        wordBreak: 'break-word',
                                                                        borderRadius: 1
                                                                    }}
                                                                >
                                                                    {feed.url.split(/({[^}]+})/).map((part, index) => {
                                                                        if (part.match(/{[^}]+}/)) {
                                                                            if (part === '{q}') {
                                                                                return (
                                                                                    <span 
                                                                                        key={index} 
                                                                                        style={{ 
                                                                                            backgroundColor: '#fff8e1', 
                                                                                            color: '#ff6d00',
                                                                                            padding: '1px 4px',
                                                                                            borderRadius: '2px',
                                                                                            margin: '0 2px',
                                                                                            fontWeight: 'bold',
                                                                                            border: '1px solid #ffe082'
                                                                                        }}
                                                                                    >
                                                                                        {part}
                                                                                    </span>
                                                                                );
                                                                            }
                                                                            return (
                                                                                <span 
                                                                                    key={index} 
                                                                                    style={{ 
                                                                                        backgroundColor: '#e3f2fd', 
                                                                                        color: '#1976d2',
                                                                                        padding: '1px 4px',
                                                                                        borderRadius: '2px',
                                                                                        margin: '0 2px',
                                                                                        fontWeight: 'medium',
                                                                                        border: '1px solid #bbdefb'
                                                                                    }}
                                                                                >
                                                                                    {part}
                                                                                </span>
                                                                            );
                                                                        }
                                                                        return <span key={index}>{part}</span>;
                                                                    })}
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                    ) : null;
                                                })()}
                                            </CardContent>
                                        </Card>
                                    )}
                                    
                                    <Box sx={{ mb: 3 }}>
                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                                            Campaign Targeting
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Keyword to target"
                                                    variant="outlined"
                                                    required={true}
                                                    value={keyword}
                                                    onChange={handleKeywordChange}
                                                    size="small"
                                                    helperText="The main keyword your campaign will target"
                                                />
                                            </Grid>
                                            
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Ad Research Topic"
                                                    variant="outlined"
                                                    required={true}
                                                    value={topic}
                                                    onChange={(event) => setTopic(event.target.value)}
                                                    size="small"
                                                    helperText={
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <span>Enter a broader topic to find related ads (keep it general)</span>
                                                            {topic.length > 4 && (
                                                                <Button 
                                                                    size="small" 
                                                                    startIcon={<VisibilityOutlined fontSize="small" />}
                                                                    onClick={() => setOpenDialog(true)}
                                                                    sx={{ ml: 1, fontSize: '0.75rem' }}
                                                                >
                                                                    Show Inspiration
                                                                </Button>
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                            </Grid>
                                            
                                            {feedUrl && (
                                                <Grid item xs={12}>
                                                    <Box sx={{ p: 2, bgcolor: '#f5f5f5', borderRadius: 1, mt: 1 }}>
                                                        <Typography variant="caption" color="text.secondary">
                                                            Preview URL:
                                                        </Typography>
                                                        <Paper 
                                                            variant="outlined" 
                                                            sx={{ 
                                                                p: 1.5, 
                                                                backgroundColor: '#fafafa',
                                                                fontFamily: 'monospace',
                                                                fontSize: '0.7rem',
                                                                overflow: 'auto',
                                                                wordBreak: 'break-word',
                                                                mt: 0.5,
                                                                borderRadius: 1,
                                                                height: '50px'
                                                            }}
                                                        >
                                                            {feedUrl.split(/({[^}]+})/).map((part, index) => {
                                                                if (part.match(/{[^}]+}/)) {
                                                                    if (part === '{q}') {
                                                                        return (
                                                                            <span 
                                                                                key={index} 
                                                                                style={{ 
                                                                                    backgroundColor: '#fff8e1', 
                                                                                    color: '#ff6d00',
                                                                                    padding: '1px 4px',
                                                                                    borderRadius: '2px',
                                                                                    margin: '0 2px',
                                                                                    fontWeight: 'bold',
                                                                                    border: '1px solid #ffe082'
                                                                                }}
                                                                            >
                                                                                {part}
                                                                            </span>
                                                                        );
                                                                    }
                                                                    return (
                                                                        <span 
                                                                            key={index} 
                                                                            style={{ 
                                                                                backgroundColor: '#e3f2fd', 
                                                                                color: '#1976d2',
                                                                                padding: '1px 4px',
                                                                                borderRadius: '2px',
                                                                                margin: '0 2px',
                                                                                fontWeight: 'medium',
                                                                                border: '1px solid #bbdefb'
                                                                            }}
                                                                        >
                                                                            {part}
                                                                        </span>
                                                                    );
                                                                }
                                                                return <span key={index}>{part}</span>;
                                                            })}
                                                        </Paper>
                                                    </Box>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                    
                                    <Box sx={{ mb: 3 }}>
                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                                            Location & Language
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body2" gutterBottom>
                                                    Country
                                                </Typography>
                                                <CountriesList countries={countries} setCountries={setCountries} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body2" gutterBottom>
                                                    Language
                                                </Typography>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="language-select-label">Language</InputLabel>
                                                    <Select
                                                        labelId="language-select-label"
                                                        value={language}
                                                        label="Language"
                                                        onChange={(e) => setLanguage(e.target.value)}
                                                    >
                                                        <MenuItem value="English">English</MenuItem>
                                                        <MenuItem value="Spanish">Spanish</MenuItem>
                                                        <MenuItem value="French">French</MenuItem>
                                                        <MenuItem value="German">German</MenuItem>
                                                        <MenuItem value="Italian">Italian</MenuItem>
                                                        <MenuItem value="Portuguese">Portuguese</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                        <div /> {/* Empty div for flex spacing */}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={loadingStart || !keyword || !topic || countries.length === 0 || !selectedFeed}
                                            startIcon={loadingStart ? <CircularProgress size={20} color="inherit" /> : null}
                                            endIcon={!loadingStart && <ArrowForward />}
                                        >
                                            {loadingStart ? 'Starting...' : 'Start Campaign Creation'}
                                        </Button>
                                    </Box>
                                </form>
                            </CardContent>
                        </Card>
                    </div>
                </Fade>

                {/* Step 2: Creative Assets */}
                <Fade in={activeStep === 1} timeout={300}>
                    <div style={{ display: activeStep === 1 ? 'block' : 'none' }}>
                        {startDone && (
                            <>
                                <Card sx={{ mb: 4, borderRadius: 2 }}>
                                    <CardContent sx={{ px: { xs: 2, sm: 3 }, pt: 2, pb: 3 }}>
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                                                Headlines
                                            </Typography>
                                            <Divider sx={{ mb: 2 }} />
                                            
                                            <HeadlineSelector 
                                                topic={topic} 
                                                headlines={headlines} 
                                                selectedHeadlines={selectedHeadlines}
                                                setSelectedHeadlines={setSelectedHeadlines}
                                                guidelines={selectedHeadlineGuideline}
                                                setHeadlines={setHeadlines}
                                                healines={headlines}
                                            />
                                            
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={handleRecalculateHeadlines}
                                                disabled={loadingRecalculateHeadlines}
                                                startIcon={loadingRecalculateHeadlines ? <CircularProgress size={16} color="inherit" /> : null}
                                                sx={{ mt: 2 }}
                                            >
                                                {loadingRecalculateHeadlines ? 'Generating...' : 'Generate More Headlines'}
                                            </Button>
                                        </Box>
                                        
                                        <Box sx={{ mt: 4, mb: 2 }}>
                                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                                                Description
                                            </Typography>
                                            <Divider sx={{ mb: 2 }} />
                                            
                                            <TextField 
                                                fullWidth
                                                value={description}
                                                onChange={(event) => setDescription(event.target.value)}
                                                placeholder="Brief description for your ads"
                                                variant="outlined"
                                                size="small"
                                                helperText={`${description.length}/150 characters`}
                                                FormHelperTextProps={{
                                                    sx: { textAlign: 'right' }
                                                }}
                                            />
                                        </Box>
                                    </CardContent>
                                </Card>
                                
                                <Card sx={{ mb: 4, borderRadius: 2 }}>
                                    <CardContent sx={{ px: { xs: 2, sm: 3 }, pt: 2, pb: 3 }}>
                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                                            Images
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        
                                        <ImageSelector 
                                            initialImages={images} 
                                            selectedImages={selectedImages}
                                            setSelectedImages={setSelectedImages}
                                        />
                                    </CardContent>
                                </Card>
                                
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                    <Button
                                        variant="outlined"
                                        onClick={handleBack}
                                        startIcon={<ArrowBack />}
                                    >
                                        Back
                                    </Button>
                                    
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        disabled={selectedHeadlines.size === 0 || selectedImages.length === 0}
                                        endIcon={<ArrowForward />}
                                    >
                                        Continue
                                    </Button>
                                </Box>
                            </>
                        )}
                    </div>
                </Fade>

                {/* Step 3: Ad Matrix */}
                <Fade in={activeStep === 2} timeout={300}>
                    <div style={{ display: activeStep === 2 ? 'block' : 'none' }}>
                        {startDone && selectedImages.length > 0 && selectedHeadlines.size > 0 && (
                            <>
                                <Card sx={{ mb: 4, borderRadius: 2 }}>
                                    <CardContent sx={{ px: { xs: 2, sm: 3 }, pt: 2, pb: 3 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                                Create Your Ad Set
                                            </Typography>
                                            
                                            <Chip 
                                                label={`${selectedCards.length} selected`} 
                                                color={selectedCards.length > 0 ? "primary" : "default"}
                                                size="small"
                                                variant={selectedCards.length > 0 ? "filled" : "outlined"}
                                            />
                                        </Box>
                                        <Divider sx={{ mb: 2 }} />
                                        
                                        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                                            Combine headlines and images to create compelling native ads for your campaign
                                        </Typography>
                                        
                                        <MatrixGridComponent 
                                            headlines={selectedHeadlines} 
                                            images={selectedImages} 
                                            description={description}
                                            selectedCards={selectedCards} 
                                            setSelectedCards={setSelectedCards}
                                        />
                                    </CardContent>
                                </Card>
                                
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                    <Button
                                        variant="outlined"
                                        onClick={handleBack}
                                        startIcon={<ArrowBack />}
                                    >
                                        Back
                                    </Button>
                                    
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        disabled={selectedCards.length === 0}
                                        endIcon={<ArrowForward />}
                                    >
                                        Continue
                                    </Button>
                                </Box>
                            </>
                        )}
                    </div>
                </Fade>

                {/* Step 4: Campaign Launch */}
                <Fade in={activeStep === 3} timeout={300}>
                    <div style={{ display: activeStep === 3 ? 'block' : 'none' }}>
                        {selectedCards.length > 0 && (
                            <form onSubmit={handleCreateCampaigns}>
                                <Card sx={{ mb: 4, borderRadius: 2 }}>
                                    <CardContent sx={{ px: { xs: 2, sm: 3 }, pt: 2, pb: 3 }}>
                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                                            Campaign Settings
                                        </Typography>
                                        <Divider sx={{ mb: 3 }} />
                                        
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label="Campaign Title"
                                                    variant="outlined"
                                                    size="small"
                                                    value={campaignTitle}
                                                    onChange={handleTitleChange}
                                                    helperText="Use {country} or {device} as placeholders if needed"
                                                />
                                            </Grid>
                                            
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Daily Budget"
                                                    variant="outlined"
                                                    required
                                                    type="number"
                                                    size="small"
                                                    InputProps={{
                                                        startAdornment: <span style={{ marginRight: 8 }}>$</span>
                                                    }}
                                                    value={campaignBudget}
                                                    onChange={(event) => setCampaignBudget(event.target.value)}
                                                />
                                            </Grid>
                                            
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth size="small" required>
                                                    <InputLabel id="device-select-label">Device Targeting</InputLabel>
                                                    <Select
                                                        labelId="device-select-label"
                                                        label="Device Targeting"
                                                        multiple
                                                        value={campaignDevice}
                                                        onChange={(event) => setCampaignDevice(event.target.value)}
                                                        renderValue={(selected) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                {selected.map((value) => (
                                                                    <Chip 
                                                                        key={value} 
                                                                        label={value === 'PHON' ? 'Mobile' : 'Desktop'} 
                                                                        size="small"
                                                                    />
                                                                ))}
                                                            </Box>
                                                        )}
                                                    >
                                                        <MenuItem value="PHON">Smartphone</MenuItem>
                                                        <MenuItem value="DESK">Desktop</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        
                                        <Box sx={{ mt: 4 }}>
                                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                                                Campaign Summary
                                            </Typography>
                                            <Divider sx={{ mb: 2 }} />
                                            
                                            <CampaignPreview 
                                                campaignTitle={campaignTitle}
                                                campaignBudget={campaignBudget}
                                                campaignType={campaignType}
                                                campaignDevice={campaignDevice}
                                                countries={countries}
                                                keyword={keyword}
                                                description={description}
                                                feeds={feeds}
                                                selectedFeed={selectedFeed}
                                                selectedCards={selectedCards}
                                            />
                                        </Box>
                                    </CardContent>
                                </Card>
                                
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                    <Button
                                        variant="outlined"
                                        onClick={handleBack}
                                        startIcon={<ArrowBack />}
                                    >
                                        Back
                                    </Button>
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={loadingCreateCampaign || !selectedFeed}
                                        startIcon={loadingCreateCampaign && <CircularProgress size={20} color="inherit" />}
                                    >
                                        {loadingCreateCampaign 
                                            ? 'Creating Campaign...' 
                                            : `Launch ${countries.length} Campaign${countries.length > 1 ? 's' : ''} (${selectedCards.length * countries.length * campaignDevice.length} ads)`}
                                    </Button>
                                </Box>
                                
                                {!selectedFeed && (
                                    <Alert severity="error" sx={{ mt: 2 }}>
                                        Please select a feed before creating campaigns
                                    </Alert>
                                )}
                                
                                {error && (
                                    <Alert severity="error" sx={{ mt: 2 }}>
                                        {error}
                                    </Alert>
                                )}
                            </form>
                        )}
                    </div>
                </Fade>
            </Container>
        </Layout>
    );
}

export default Creation;
