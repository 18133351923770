import React, { useState, useEffect, useRef } from 'react';
import { 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem, 
    Box, 
    Button, 
    Typography, 
    CircularProgress, 
    Alert,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Chip,
    Paper,
    Divider
} from '@mui/material';
import { Refresh, AddCircleOutline, InfoOutlined, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import config from '../config';
import axiosInstance from '../utils/axiosConfig';

// Custom component for URL input with syntax highlighting
const HighlightedUrlInput = ({ value, onChange, placeholder, id = 'url-input', trafficSource = 'taboola' }) => {
    const [focused, setFocused] = useState(false);
    const textAreaRef = useRef(null);
    
    return (
        <Box sx={{ position: 'relative', mb: 1 }}>
            <Paper
                variant="outlined"
                sx={{
                    position: 'relative',
                    cursor: 'text',
                    minHeight: '200px',
                    padding: '12px',
                    backgroundColor: focused ? '#fff' : '#fafafa',
                    borderColor: focused ? '#1976d2' : 'rgba(0, 0, 0, 0.23)',
                    borderWidth: focused ? '2px' : '1px',
                    transition: 'background-color 0.2s, border-color 0.2s',
                    '&:hover': {
                        borderColor: focused ? '#1976d2' : 'rgba(0, 0, 0, 0.87)',
                    }
                }}
                onClick={() => textAreaRef.current?.focus()}
            >
                <textarea
                    ref={textAreaRef}
                    id={id}
                    value={value}
                    onChange={(e) => onChange(e)}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    style={{
                        width: '100%',
                        height: '100%',
                        minHeight: '200px',
                        border: 'none',
                        outline: 'none',
                        background: 'transparent',
                        fontFamily: 'monospace',
                        fontSize: '1rem',
                        lineHeight: '1.5',
                        resize: 'vertical',
                        padding: 0,
                        overflowY: 'auto',
                    }}
                    placeholder={placeholder}
                    spellCheck="false"
                    autoComplete="off"
                    autoCorrect="off"
                />
            </Paper>
            <Typography variant="caption" color="text.secondary" sx={{ ml: 1.5 }}>
                Use {trafficSource.charAt(0).toUpperCase() + trafficSource.slice(1)} macros for dynamic values. Click on macros below to insert them.
            </Typography>
        </Box>
    );
};

// Taboola-specific macros categorized
const TABOOLA_MACROS = {
    campaign: [
        { tag: '{campaign_id}', description: 'A unique Taboola campaign ID' },
        { tag: '{campaign_name}', description: 'The campaign name as defined in Backstage' },
        { tag: '{campaign_item_id}', description: 'A unique Taboola item ID from Top Campaign Content report' },
        { tag: '{creative_name}', description: 'Custom creative name given by the advertiser (optional)' },
        { tag: '{custom_id}', description: 'Custom creative ID given by the advertiser (optional)' }
    ],
    publisher: [
        { tag: '{site}', description: 'The publisher site or application name' },
        { tag: '{site_id}', description: 'A unique site ID for the publisher site or app' },
        { tag: '{site_domain}', description: 'The name of the website or URL' },
        { tag: '{placement}', description: 'Placement on the page' },
        { tag: '{placement_id}', description: 'Placement ID' }
    ],
    ad: [
        { tag: '{title}', description: 'The headline for your item' },
        { tag: '{thumbnail}', description: 'The image or video URL' }
    ],
    tracking: [
        { tag: '{click_id}', description: 'Taboola click ID' },
        { tag: '{tblci}', description: 'Taboola click identifier' },
        { tag: '{external_id}', description: 'External tracking ID' },
        { tag: '{cpc}', description: 'Realtime CPC bid of each click (encrypted)' },
        { tag: '{trackingField1}', description: 'Taboola tracking field 1' },
        { tag: '{trackingField2}', description: 'Taboola tracking field 2' },
        { tag: '{trackingField3}', description: 'Taboola tracking field 3' },
        { tag: '{trackingField4}', description: 'Taboola tracking field 4' },
        { tag: '{trackingField5}', description: 'Taboola tracking field 5' }
    ],
    user: [
        { tag: '{platform}', description: 'User device platform (Desktop/Mobile/Tablet)' },
        { tag: '{ip_address}', description: 'User IP address' },
        { tag: '{referrer}', description: 'Referring URL' },
        { tag: '{user_agent}', description: 'User agent string' },
        { tag: '{device_id}', description: 'Device ID' },
        { tag: '{os}', description: 'Operating system' },
        { tag: '{country}', description: 'User country' },
        { tag: '{region}', description: 'User region/state' },
        { tag: '{city}', description: 'User city' },
        { tag: '{postal_code}', description: 'User postal/zip code' },
        { tag: '{timestamp}', description: 'Timestamp when the item was served' }
    ],
    technical: [
        { tag: '{cachebuster}', description: 'Generates a unique random numeric string per impression for cache-busting' },
        { tag: '${GDPR}', description: 'Indicates if GDPR applies: 0=does not apply, 1=applies' },
        { tag: '${GDPR_CONSENT_XXXXX}', description: 'Encodes the TC string (replace XXXXX with numeric Vendor ID)' }
    ]
};

// Outbrain-specific macros categorized
const OUTBRAIN_MACROS = {
    campaign: [
        { tag: '{campaign_id}', description: 'Outbrain campaign ID' },
        { tag: '{campaign_name}', description: 'Outbrain campaign name' }
    ],
    publisher: [
        { tag: '{publisher_id}', description: 'Publisher ID' },
        { tag: '{publisher_name}', description: 'Publisher name' },
        { tag: '{section_id}', description: 'Section ID on publisher site' },
        { tag: '{section_name}', description: 'Section name on publisher site' }
    ],
    ad: [
        { tag: '{headline}', description: 'Ad headline text' },
        { tag: '{image}', description: 'Ad image URL' }
    ],
    tracking: [
        { tag: '{click_id}', description: 'Outbrain click ID' }
    ],
    user: [
        { tag: '{device_type}', description: 'User device type (Desktop/Mobile/Tablet)' },
        { tag: '{geo_country}', description: 'User country' },
        { tag: '{geo_region}', description: 'User region' },
        { tag: '{geo_city}', description: 'User city' }
    ],
    technical: [
        { tag: '{random}', description: 'Random number for cache-busting' },
        { tag: '{timestamp}', description: 'Current timestamp' }
    ]
};

// Yahoo-specific macros categorized
const YAHOO_MACROS = {
    campaign: [
        { tag: '{campaign_id}', description: 'Yahoo campaign ID' },
        { tag: '{campaign_name}', description: 'Yahoo campaign name' }
    ],
    publisher: [
        { tag: '{site_id}', description: 'Publisher site ID' },
        { tag: '{site_name}', description: 'Publisher site name' }
    ],
    ad: [
        { tag: '{creative_id}', description: 'Creative ID' },
        { tag: '{headline}', description: 'Ad headline' }
    ],
    tracking: [
        { tag: '{yahoo_click_id}', description: 'Yahoo click tracking ID' }
    ],
    user: [
        { tag: '{device}', description: 'User device type' },
        { tag: '{country}', description: 'User country' }
    ],
    technical: [
        { tag: '{cache_buster}', description: 'Cache busting parameter' }
    ]
};

// MGID-specific macros categorized
const MGID_MACROS = {
    campaign: [
        { tag: '{campaign_id}', description: 'MGID campaign ID' },
        { tag: '{campaign_name}', description: 'MGID campaign name' }
    ],
    publisher: [
        { tag: '{widget_id}', description: 'MGID widget ID' },
        { tag: '{publisher_id}', description: 'Publisher ID' }
    ],
    ad: [
        { tag: '{creative_id}', description: 'Creative ID' },
        { tag: '{teaser_id}', description: 'Teaser ID' }
    ],
    tracking: [
        { tag: '{click_id}', description: 'MGID click ID' }
    ],
    user: [
        { tag: '{ua_platform}', description: 'User agent platform' },
        { tag: '{country}', description: 'User country' }
    ],
    technical: [
        { tag: '{random}', description: 'Random value for cache busting' }
    ]
};

// Add more traffic source specific macros here as needed
const TRAFFIC_SOURCE_MACROS = {
    'taboola': TABOOLA_MACROS,
    'outbrain': OUTBRAIN_MACROS,
    'yahoo': YAHOO_MACROS,
    'mgid': MGID_MACROS,
};

const FeedSelector = ({ selectedFeed, onFeedChange, onFeedsLoaded }) => {
    const [feeds, setFeeds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refreshing, setRefreshing] = useState(false);
    const [openAddFeedDialog, setOpenAddFeedDialog] = useState(false);
    const [openEditFeedDialog, setOpenEditFeedDialog] = useState(false);
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
    const [newFeed, setNewFeed] = useState({ name: '', url: '', type: 'RSOC', account_id: '', traffic_source: 'taboola' });
    const [editFeed, setEditFeed] = useState(null);
    const [taboolaAccounts, setTaboolaAccounts] = useState([]);
    const [loadingAccounts, setLoadingAccounts] = useState(false);
    
    // Function to fetch Taboola accounts
    const fetchTaboolaAccounts = async () => {
        try {
            setLoadingAccounts(true);
            const response = await axiosInstance.get(`/feeds/taboola-accounts`);
            setTaboolaAccounts(response.data || []);
        } catch (error) {
            console.error('Error fetching Taboola accounts:', error);
            setError('Failed to fetch Taboola accounts. Please try again later.');
        } finally {
            setLoadingAccounts(false);
        }
    };
    
    // Watch for traffic source changes in the new feed dialog
    useEffect(() => {
        if (newFeed.traffic_source === 'taboola' && openAddFeedDialog) {
            fetchTaboolaAccounts();
        }
    }, [newFeed.traffic_source, openAddFeedDialog]);
    
    // Watch for traffic source changes in the edit feed dialog
    useEffect(() => {
        if (editFeed && editFeed.traffic_source === 'taboola' && openEditFeedDialog) {
            fetchTaboolaAccounts();
        }
    }, [editFeed, openEditFeedDialog]);

    // Get macros based on selected traffic source
    const getMacrosForTrafficSource = (trafficSource) => {
        return TRAFFIC_SOURCE_MACROS[trafficSource] || TABOOLA_MACROS;
    };

    // Function to insert macro at cursor position in URL field
    const insertMacroAtCursor = (macro, isEdit = false) => {
        // Get the textarea element
        const textArea = document.getElementById(isEdit ? 'edit-feed-url-field' : 'feed-url-field');
        if (!textArea) return;
        
        // Focus the textarea first to ensure it has focus
        textArea.focus();
        
        // Get current selection positions
        const start = textArea.selectionStart;
        const end = textArea.selectionEnd;
        
        // Check if text is selected
        const hasSelection = start !== end;
        
        // Split text around selection/cursor
        const textBefore = isEdit 
            ? editFeed.url.substring(0, start) 
            : newFeed.url.substring(0, start);
        const textAfter = isEdit 
            ? editFeed.url.substring(hasSelection ? end : start) 
            : newFeed.url.substring(hasSelection ? end : start);
        
        // Create new URL value with the macro inserted (replacing selection if any)
        const newValue = textBefore + macro + textAfter;
        
        // Update the feed URL
        if (isEdit) {
            setEditFeed({
                ...editFeed,
                url: newValue
            });
        } else {
            setNewFeed({
                ...newFeed,
                url: newValue
            });
        }
        
        // Calculate new cursor position after the inserted macro
        const newPosition = start + macro.length;
        
        // Use requestAnimationFrame to ensure DOM is updated before setting cursor
        requestAnimationFrame(() => {
            if (textArea) {
                textArea.focus();
                textArea.selectionStart = newPosition;
                textArea.selectionEnd = newPosition;
            }
        });
    };

    // Function to open edit dialog for a feed
    const openEditDialog = (feedId) => {
        const feedToEdit = feeds.find(feed => feed.id === feedId);
        if (feedToEdit) {
            setEditFeed({...feedToEdit});
            setOpenEditFeedDialog(true);
        }
    };

    const fetchFeeds = async () => {
        const apiUrl = `/feeds`;
        console.log('FeedSelector: Fetching from API URL:', config.API_URL + apiUrl);
        
        try {
            setLoading(true);
            setError(null);
            const response = await axiosInstance.get(apiUrl);
            console.log('Feeds response:', response);
            setFeeds(response.data);
            // Pass feeds back to parent component
            if (onFeedsLoaded) {
                onFeedsLoaded(response.data);
            }
        } catch (error) {
            console.error('Error fetching feeds:', error);
            setError(`Failed to load feeds: ${error.message}. Please try again.`);
        } finally {
            setLoading(false);
            setRefreshing(false);
        }
    };

    useEffect(() => {
        fetchFeeds();
    }, []);

    const handleRefresh = () => {
        setRefreshing(true);
        fetchFeeds();
    };

    const handleAddFeed = async () => {
        const apiUrl = `/feeds`;
        console.log('POST to API URL:', apiUrl);
        
        try {
            setLoading(true);
            const response = await axiosInstance.post(apiUrl, newFeed);
            console.log('Add feed response:', response);
            // Add the new feed to the list
            setFeeds([...feeds, response.data]);
            // Pass updated feeds back to parent
            if (onFeedsLoaded) {
                onFeedsLoaded([...feeds, response.data]);
            }
            // Select the new feed
            onFeedChange(response.data.id);
            // Close the dialog
            setOpenAddFeedDialog(false);
            // Clear the form
            setNewFeed({ name: '', url: '', type: 'RSOC', account_id: '', traffic_source: 'taboola' });
        } catch (error) {
            console.error('Error adding feed:', error);
            setError(`Failed to add feed: ${error.message}. Please try again.`);
        } finally {
            setLoading(false);
        }
    };

    // Function to update an existing feed
    const handleEditFeed = async () => {
        if (!editFeed || !editFeed.id) return;
        
        const apiUrl = `/feeds/${editFeed.id}`;
        console.log('PUT to API URL:', apiUrl);
        
        try {
            setLoading(true);
            const response = await axiosInstance.put(apiUrl, editFeed);
            console.log('Edit feed response:', response);
            
            // Update the feed in the list
            const updatedFeeds = feeds.map(feed => 
                feed.id === editFeed.id ? response.data : feed
            );
            setFeeds(updatedFeeds);
            
            // Pass updated feeds back to parent
            if (onFeedsLoaded) {
                onFeedsLoaded(updatedFeeds);
            }
            
            // Close the dialog
            setOpenEditFeedDialog(false);
            
            // Clear the form
            setEditFeed(null);
        } catch (error) {
            console.error('Error updating feed:', error);
            setError(`Failed to update feed: ${error.message}. Please try again.`);
        } finally {
            setLoading(false);
        }
    };

    // Function to delete a feed
    const handleDeleteFeed = async () => {
        if (!editFeed || !editFeed.id) return;
        
        const apiUrl = `/feeds/${editFeed.id}`;
        console.log('DELETE to API URL:', apiUrl);
        
        try {
            setLoading(true);
            await axiosInstance.delete(apiUrl);
            
            // Remove the feed from the list
            const updatedFeeds = feeds.filter(feed => feed.id !== editFeed.id);
            setFeeds(updatedFeeds);
            
            // Pass updated feeds back to parent
            if (onFeedsLoaded) {
                onFeedsLoaded(updatedFeeds);
            }
            
            // If the deleted feed was selected, clear the selection
            if (selectedFeed === editFeed.id) {
                onFeedChange('');
            }
            
            // Close both dialogs
            setOpenConfirmDeleteDialog(false);
            setOpenEditFeedDialog(false);
            
            // Clear the form
            setEditFeed(null);
        } catch (error) {
            console.error('Error deleting feed:', error);
            setError(`Failed to delete feed: ${error.message}. Please try again.`);
            setOpenConfirmDeleteDialog(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ minWidth: 120, mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="subtitle1" component="span">
                    Select Feed
                </Typography>
                <Box>
                    <Tooltip title="Add new feed">
                        <IconButton 
                            size="small" 
                            onClick={() => setOpenAddFeedDialog(true)} 
                            sx={{ mr: 1 }}
                        >
                            <AddCircleOutline />
                        </IconButton>
                    </Tooltip>
                    {selectedFeed && (
                        <Tooltip title="Edit selected feed">
                            <IconButton 
                                size="small" 
                                onClick={() => openEditDialog(selectedFeed)}
                                sx={{ mr: 1 }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title="Refresh feeds">
                        <span>
                            <IconButton 
                                size="small" 
                                onClick={handleRefresh} 
                                disabled={loading || refreshing}
                            >
                                {refreshing ? <CircularProgress size={20} /> : <Refresh />}
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
            
            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}
            
            <FormControl fullWidth disabled={loading}>
                <InputLabel id="feed-select-label">Feed</InputLabel>
                <Select
                    labelId="feed-select-label"
                    id="feed-select"
                    value={selectedFeed || ''}
                    label="Feed"
                    onChange={(e) => onFeedChange(e.target.value)}
                    startAdornment={
                        loading && !refreshing ? (
                            <CircularProgress size={20} sx={{ mr: 1 }} />
                        ) : null
                    }
                >
                    {feeds.length === 0 ? (
                        <MenuItem disabled value="">
                            <em>No feeds available</em>
                        </MenuItem>
                    ) : (
                        feeds.map((feed) => (
                            <MenuItem key={feed.id} value={feed.id}>
                                {feed.name} {feed.type && <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>({feed.type})</Typography>}
                            </MenuItem>
                        ))
                    )}
                </Select>
            </FormControl>

            {/* Add Feed Dialog */}
            <Dialog 
                open={openAddFeedDialog} 
                onClose={() => setOpenAddFeedDialog(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    Add New Feed
                </DialogTitle>
                <DialogContent>
                    <TextField
                        select
                        margin="dense"
                        label="Traffic Source"
                        fullWidth
                        variant="outlined"
                        value={newFeed.traffic_source}
                        onChange={(e) => setNewFeed({...newFeed, traffic_source: e.target.value})}
                        required
                        autoFocus
                    >
                        <MenuItem value="taboola">Taboola</MenuItem>
                        <MenuItem value="outbrain">Outbrain</MenuItem>
                        <MenuItem value="yahoo">Yahoo Native</MenuItem>
                        <MenuItem value="mgid">MGID</MenuItem>
                    </TextField>
                
                    <TextField
                        margin="dense"
                        label="Feed Name"
                        fullWidth
                        variant="outlined"
                        value={newFeed.name}
                        onChange={(e) => setNewFeed({...newFeed, name: e.target.value})}
                    />
                    
                    {newFeed.traffic_source === 'taboola' ? (
                        <FormControl fullWidth margin="dense" variant="outlined">
                            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <InputLabel id="taboola-account-select-label">Taboola Account</InputLabel>
                                    <Select
                                        labelId="taboola-account-select-label"
                                        label="Taboola Account"
                                        value={newFeed.account_id}
                                        onChange={(e) => setNewFeed({...newFeed, account_id: e.target.value})}
                                        startAdornment={
                                            loadingAccounts ? (
                                                <CircularProgress size={20} sx={{ mr: 1 }} />
                                            ) : null
                                        }
                                        required
                                        fullWidth
                                        disabled={loadingAccounts}
                                    >
                                        {loadingAccounts ? (
                                            <MenuItem disabled value="">
                                                <em>Loading accounts...</em>
                                            </MenuItem>
                                        ) : taboolaAccounts.length === 0 ? (
                                            <MenuItem disabled value="">
                                                <em>No accounts available</em>
                                            </MenuItem>
                                        ) : (
                                            taboolaAccounts.map((account) => (
                                                <MenuItem key={account.account_id} value={account.account_id}>
                                                    {account.name} ({account.account_id})
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </Box>
                                <Tooltip title="Refresh Taboola accounts">
                                    <IconButton 
                                        onClick={fetchTaboolaAccounts}
                                        disabled={loadingAccounts}
                                        sx={{ mt: 1, ml: 1 }}
                                    >
                                        {loadingAccounts ? <CircularProgress size={20} /> : <Refresh />}
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            {taboolaAccounts.length === 0 && !loadingAccounts && (
                                <Alert severity="info" sx={{ mt: 1, mb: 1 }}>
                                    No Taboola accounts found. Make sure your Taboola API credentials are valid.
                                </Alert>
                            )}
                            <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                Select the Taboola account to use for this feed
                            </Typography>
                        </FormControl>
                    ) : (
                        <TextField
                            margin="dense"
                            label="Account ID"
                            fullWidth
                            variant="outlined"
                            value={newFeed.account_id}
                            onChange={(e) => setNewFeed({...newFeed, account_id: e.target.value})}
                            helperText="The account ID associated with this feed"
                            required
                        />
                    )}
                    
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Feed URL / Tracking Template
                            <Tooltip title={`Enter your feed URL with ${newFeed.traffic_source.charAt(0).toUpperCase() + newFeed.traffic_source.slice(1)} macros like {campaign_id}, {site}, etc. These macros will be replaced with actual values when ads are created.`}>
                                <IconButton size="small" sx={{ ml: 1 }}>
                                    <InfoOutlined fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                        
                        {/* Custom styled text area with highlighting */}
                        <HighlightedUrlInput
                            value={newFeed.url}
                            onChange={(e) => setNewFeed({...newFeed, url: e.target.value})}
                            placeholder={`https://example.com/feed?campaign={campaign_id}&site={site}&placement={placement}`}
                            id="feed-url-field"
                            trafficSource={newFeed.traffic_source}
                        />
                    </Box>
                    
                    <Divider sx={{ my: 2 }} />
                    
                    <Typography variant="subtitle2" gutterBottom>
                        Available {newFeed.traffic_source.charAt(0).toUpperCase() + newFeed.traffic_source.slice(1)} Macros
                    </Typography>
                    
                    <Paper variant="outlined" sx={{ p: 2, maxHeight: '350px', overflow: 'auto' }}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {/* All standard macros for selected traffic source */}
                            {(() => {
                                const macros = getMacrosForTrafficSource(newFeed.traffic_source);
                                const allMacros = [
                                    ...macros.campaign,
                                    ...macros.publisher,
                                    ...macros.ad,
                                    ...macros.tracking,
                                    ...macros.user,
                                    ...macros.technical
                                ];
                                
                                return allMacros.map((macro, index) => (
                                    <Tooltip key={index} title={macro.description}>
                                        <Chip 
                                            label={macro.tag} 
                                            size="small" 
                                            onClick={() => insertMacroAtCursor(macro.tag, false)}
                                            color="primary"
                                            variant="outlined"
                                            sx={{ fontFamily: 'monospace' }}
                                        />
                                    </Tooltip>
                                ));
                            })()}
                            
                            {/* Special q macro */}
                            <Tooltip title="Will be replaced with the keyword from the campaign">
                                <Chip 
                                    label="{q}" 
                                    size="small" 
                                    onClick={() => insertMacroAtCursor("{q}", false)}
                                    color="error"
                                    variant="outlined"
                                    sx={{ fontFamily: 'monospace' }}
                                />
                            </Tooltip>
                        </Box>
                    </Paper>
                    
                    {/* URL Preview Section */}
                    {newFeed.url && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle2" gutterBottom>
                                URL Preview (with sample values)
                                <Tooltip title="This shows how your URL might look with example values for the macros">
                                    <IconButton size="small" sx={{ ml: 1 }}>
                                        <InfoOutlined fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                            <Paper 
                                variant="outlined" 
                                sx={{ 
                                    p: 1, 
                                    backgroundColor: '#f8f8f8',
                                    fontFamily: 'monospace',
                                    fontSize: '0.75rem',
                                    maxHeight: '100px',
                                    overflow: 'auto',
                                    wordBreak: 'break-word'
                                }}
                            >
                                {newFeed.url.split(/({[^}]+})/).map((part, index) => {
                                    // Get sample values for macros
                                    if (part.match(/{campaign_id}/)) return <span key={index} style={{ backgroundColor: '#f0f8ff' }}>12345678</span>;
                                    if (part.match(/{campaign_name}/)) return <span key={index} style={{ backgroundColor: '#f0f8ff' }}>Example_Campaign_Name</span>;
                                    if (part.match(/{site}/)) return <span key={index} style={{ backgroundColor: '#f0f8ff' }}>publisher-site</span>;
                                    if (part.match(/{site_domain}/)) return <span key={index} style={{ backgroundColor: '#f0f8ff' }}>example.com</span>;
                                    if (part.match(/{placement}/)) return <span key={index} style={{ backgroundColor: '#f0f8ff' }}>Below_Article</span>;
                                    if (part.match(/{title}/)) return <span key={index} style={{ backgroundColor: '#f0f8ff' }}>Example_Ad_Title</span>;
                                    if (part.match(/{tblci}/)) return <span key={index} style={{ backgroundColor: '#f0f8ff' }}>GiBM4tXYF_18447814_123456789</span>;
                                    if (part.match(/{click_id}/)) return <span key={index} style={{ backgroundColor: '#f0f8ff' }}>abcd1234efgh5678</span>;
                                    if (part.match(/{external_id}/)) return <span key={index} style={{ backgroundColor: '#f0f8ff' }}>ext-12345-abcde</span>;
                                    if (part.match(/{country}/)) return <span key={index} style={{ backgroundColor: '#f0f8ff' }}>US</span>;
                                    if (part.match(/{city}/)) return <span key={index} style={{ backgroundColor: '#f0f8ff' }}>New_York</span>;
                                    if (part.match(/{trackingField\d}/)) return <span key={index} style={{ backgroundColor: '#f0f8ff' }}>tracking_value</span>;
                                    
                                    // For any other macro
                                    if (part.match(/{[^}]+}/)) {
                                        return <span key={index} style={{ backgroundColor: '#f0f8ff' }}>sample_value</span>;
                                    }
                                    
                                    return <span key={index}>{part}</span>;
                                })}
                            </Paper>
                        </Box>
                    )}
                    
                    <TextField
                        select
                        margin="dense"
                        label="Feed Type"
                        fullWidth
                        variant="outlined"
                        value={newFeed.type}
                        onChange={(e) => setNewFeed({...newFeed, type: e.target.value})}
                        sx={{ mt: 2 }}
                    >
                        <MenuItem value="RSOC">RSOC (Regular Feed Campaigns)</MenuItem>
                        <MenuItem value="N2S">N2S (Native to Search)</MenuItem>
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddFeedDialog(false)}>Cancel</Button>
                    <Button 
                        onClick={handleAddFeed}
                        disabled={!newFeed.name || !newFeed.url || !newFeed.account_id}
                        variant="contained"
                    >
                        Add Feed
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Feed Dialog */}
            <Dialog 
                open={openEditFeedDialog} 
                onClose={() => setOpenEditFeedDialog(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    Edit Feed
                </DialogTitle>
                <DialogContent>
                    {editFeed && (
                        <>
                            <TextField
                                select
                                margin="dense"
                                label="Traffic Source"
                                fullWidth
                                variant="outlined"
                                value={editFeed.traffic_source}
                                onChange={(e) => setEditFeed({...editFeed, traffic_source: e.target.value})}
                                required
                                autoFocus
                            >
                                <MenuItem value="taboola">Taboola</MenuItem>
                                <MenuItem value="outbrain">Outbrain</MenuItem>
                                <MenuItem value="yahoo">Yahoo Native</MenuItem>
                                <MenuItem value="mgid">MGID</MenuItem>
                            </TextField>
                        
                            <TextField
                                margin="dense"
                                label="Feed Name"
                                fullWidth
                                variant="outlined"
                                value={editFeed.name}
                                onChange={(e) => setEditFeed({...editFeed, name: e.target.value})}
                            />
                            
                            {editFeed.traffic_source === 'taboola' ? (
                                <FormControl fullWidth margin="dense" variant="outlined">
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <InputLabel id="edit-taboola-account-select-label">Taboola Account</InputLabel>
                                            <Select
                                                labelId="edit-taboola-account-select-label"
                                                label="Taboola Account"
                                                value={editFeed.account_id || ''}
                                                onChange={(e) => setEditFeed({...editFeed, account_id: e.target.value})}
                                                startAdornment={
                                                    loadingAccounts ? (
                                                        <CircularProgress size={20} sx={{ mr: 1 }} />
                                                    ) : null
                                                }
                                                required
                                                fullWidth
                                                disabled={loadingAccounts}
                                            >
                                                {loadingAccounts ? (
                                                    <MenuItem disabled value="">
                                                        <em>Loading accounts...</em>
                                                    </MenuItem>
                                                ) : taboolaAccounts.length === 0 ? (
                                                    <MenuItem disabled value="">
                                                        <em>No accounts available</em>
                                                    </MenuItem>
                                                ) : (
                                                    taboolaAccounts.map((account) => (
                                                        <MenuItem key={account.account_id} value={account.account_id}>
                                                            {account.name} ({account.account_id})
                                                        </MenuItem>
                                                    ))
                                                )}
                                            </Select>
                                        </Box>
                                        <Tooltip title="Refresh Taboola accounts">
                                            <IconButton 
                                                onClick={fetchTaboolaAccounts}
                                                disabled={loadingAccounts}
                                                sx={{ mt: 1, ml: 1 }}
                                            >
                                                {loadingAccounts ? <CircularProgress size={20} /> : <Refresh />}
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    {taboolaAccounts.length === 0 && !loadingAccounts && (
                                        <Alert severity="info" sx={{ mt: 1, mb: 1 }}>
                                            No Taboola accounts found. Make sure your Taboola API credentials are valid.
                                        </Alert>
                                    )}
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                        Select the Taboola account to use for this feed
                                    </Typography>
                                </FormControl>
                            ) : (
                                <TextField
                                    margin="dense"
                                    label="Account ID"
                                    fullWidth
                                    variant="outlined"
                                    value={editFeed.account_id || ''}
                                    onChange={(e) => setEditFeed({...editFeed, account_id: e.target.value})}
                                    helperText="The account ID associated with this feed"
                                    required
                                />
                            )}
                            
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Feed URL / Tracking Template
                                    <Tooltip title={`Enter your feed URL with ${editFeed.traffic_source.charAt(0).toUpperCase() + editFeed.traffic_source.slice(1)} macros like {campaign_id}, {site}, etc. These macros will be replaced with actual values when ads are created.`}>
                                        <IconButton size="small" sx={{ ml: 1 }}>
                                            <InfoOutlined fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                                
                                {/* Custom styled text area with highlighting */}
                                <HighlightedUrlInput
                                    value={editFeed.url}
                                    onChange={(e) => setEditFeed({...editFeed, url: e.target.value})}
                                    placeholder={`https://example.com/feed?campaign={campaign_id}&site={site}&placement={placement}`}
                                    id="edit-feed-url-field"
                                    trafficSource={editFeed.traffic_source}
                                />
                            </Box>
                            
                            <Divider sx={{ my: 2 }} />
                            
                            <Typography variant="subtitle2" gutterBottom>
                                Available {editFeed.traffic_source.charAt(0).toUpperCase() + editFeed.traffic_source.slice(1)} Macros
                            </Typography>
                            
                            <Paper variant="outlined" sx={{ p: 2, maxHeight: '350px', overflow: 'auto' }}>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {/* All standard macros for selected traffic source */}
                                    {(() => {
                                        const macros = getMacrosForTrafficSource(editFeed.traffic_source);
                                        const allMacros = [
                                            ...macros.campaign,
                                            ...macros.publisher,
                                            ...macros.ad,
                                            ...macros.tracking,
                                            ...macros.user,
                                            ...macros.technical
                                        ];
                                        
                                        return allMacros.map((macro, index) => (
                                            <Tooltip key={index} title={macro.description}>
                                                <Chip 
                                                    label={macro.tag} 
                                                    size="small" 
                                                    onClick={() => insertMacroAtCursor(macro.tag, true)}
                                                    color="primary"
                                                    variant="outlined"
                                                    sx={{ fontFamily: 'monospace' }}
                                                />
                                            </Tooltip>
                                        ));
                                    })()}
                                    
                                    {/* Special q macro */}
                                    <Tooltip title="Will be replaced with the keyword from the campaign">
                                        <Chip 
                                            label="{q}" 
                                            size="small" 
                                            onClick={() => insertMacroAtCursor("{q}", true)}
                                            color="error"
                                            variant="outlined"
                                            sx={{ fontFamily: 'monospace' }}
                                        />
                                    </Tooltip>
                                </Box>
                            </Paper>
                            
                            <TextField
                                select
                                margin="dense"
                                label="Feed Type"
                                fullWidth
                                variant="outlined"
                                value={editFeed.type}
                                onChange={(e) => setEditFeed({...editFeed, type: e.target.value})}
                                sx={{ mt: 2 }}
                            >
                                <MenuItem value="RSOC">RSOC (Regular Feed Campaigns)</MenuItem>
                                <MenuItem value="N2S">N2S (Native to Search)</MenuItem>
                            </TextField>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setOpenConfirmDeleteDialog(true)}
                        color="error"
                        startIcon={<DeleteIcon />}
                    >
                        Delete
                    </Button>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button onClick={() => setOpenEditFeedDialog(false)}>Cancel</Button>
                    <Button 
                        onClick={handleEditFeed}
                        disabled={!editFeed || !editFeed.name || !editFeed.url || !editFeed.account_id}
                        variant="contained"
                    >
                        Update Feed
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirm Delete Dialog */}
            <Dialog
                open={openConfirmDeleteDialog}
                onClose={() => setOpenConfirmDeleteDialog(false)}
            >
                <DialogTitle>
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the feed "{editFeed?.name}"? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDeleteDialog(false)}>Cancel</Button>
                    <Button 
                        onClick={handleDeleteFeed} 
                        color="error" 
                        variant="contained"
                        startIcon={<DeleteIcon />}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default FeedSelector; 