import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Grid, CircularProgress, Typography } from "@mui/material";
import AdCard from "./AdComponent";
import config from '../config';

const SpyAdsComponent = ({ topic }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [ads, setAds] = useState([]);

    useEffect(() => {
        const fetchAds = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${config.API_URL}/spy?topic=${topic}`,
                );
                console.log(response.data)
                const creativeData = response.data;
                const _ads = Object.values(creativeData).map(ad => ({
                    title: ad.title,
                    imageName: ad.thumbnail,
                    keywords: ad.keywords,
                }));
                console.log(_ads)
                setAds(_ads);
            } catch (error) {
                console.error("Error fetching ads:", error);
            }
            setIsLoading(false);
        };

        if (topic) {
            fetchAds();
        }
    }, [topic]);

    const LoadingComponent = () => (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: '100%', width: '100%', marginTop: '20px', marginBottom: '20px' }} // Adjust the height and width
        >
            <Box textAlign="center"> {/* Center the text vertically and horizontally */}
                <CircularProgress />
                <Typography variant="h6" style={{ marginTop: '20px' }}>
                    Spying on some cool ads... Hang tight!
                </Typography>
            </Box>
        </Box>
    );


    return (
        <Grid container spacing={2}>
            {isLoading ? <LoadingComponent /> : ads?.map((ad, index) => (
                <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                    <AdCard
                        headline={ad.title}
                        imageUrl={ad.imageName}
                        description={''} // Add your own description logic if needed
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default SpyAdsComponent;
