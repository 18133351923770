import React, { useState, useEffect } from "react";
import { 
  Container, 
  Typography, 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  TablePagination,
  Chip,
  CircularProgress,
  Alert,
  Button,
  Tab,
  Tabs,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  Tooltip,
  Snackbar,
  Fade
} from '@mui/material';
import { jwtDecode as jwt_decode } from 'jwt-decode';
import axios from 'axios';
import { InfoOutlined, Refresh, CheckCircle, Error, HourglassEmpty, PlayArrow, ReplayCircleFilled } from '@mui/icons-material';
import config from '../config';
import Layout from './Layout';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';

// Task status badge component
const StatusBadge = ({ status }) => {
  let color = 'default';
  let icon = null;
  
  switch(status) {
    case 'pending':
      color = 'warning';
      icon = <HourglassEmpty fontSize="small" />;
      break;
    case 'running':
      color = 'info';
      icon = <PlayArrow fontSize="small" />;
      break;
    case 'done':
      color = 'success';
      icon = <CheckCircle fontSize="small" />;
      break;
    case 'failed':
      color = 'error';
      icon = <Error fontSize="small" />;
      break;
    default:
      color = 'default';
  }
  
  return (
    <Chip 
      icon={icon}
      label={status} 
      color={color} 
      size="small" 
      variant="outlined"
    />
  );
};

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [user, setUser] = useState(null);
  const [tabValue, setTabValue] = useState('all');
  const [selectedTask, setSelectedTask] = useState(null);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [retryingTask, setRetryingTask] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [confirmRetryDialog, setConfirmRetryDialog] = useState({ open: false, taskId: null });
  const [successAlert, setSuccessAlert] = useState({ show: false, message: '' });
  
  // Get location state to check if redirected from creation page
  const location = useLocation();
  
  useEffect(() => {
    // Check if there's a success message in the location state
    if (location.state && location.state.success) {
      setSuccessAlert({
        show: true,
        message: location.state.message || 'Campaign creation successful!'
      });
      
      // Clear the success message from location state after 8 seconds
      const timer = setTimeout(() => {
        setSuccessAlert({ show: false, message: '' });
      }, 8000);
      
      // Set tabValue to 'pending' to show newly created tasks which are typically in pending state
      // This helps users to immediately see their newly created tasks
      setTabValue('pending');
      
      return () => clearTimeout(timer);
    }
  }, [location]);
  
  useEffect(() => {
    // Get user from token
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        setUser(decodedToken);
      } catch (error) {
        console.error('Failed to decode token:', error);
        setError('Authentication error. Please log in again.');
      }
    } else {
      setError('Not authenticated. Please log in.');
    }
  }, []);
  
  useEffect(() => {
    if (user) {
      fetchTasks();
      
      // If we came from campaign creation, trigger a refresh every 3 seconds for 30 seconds
      // to show task status updates without manual refresh
      if (location.state?.fromCreation) {
        let count = 0;
        const maxRefreshes = 10; // 10 times * 3 seconds = 30 seconds of auto-refresh
        
        const refreshInterval = setInterval(() => {
          if (count < maxRefreshes) {
            fetchTasks();
            count++;
          } else {
            clearInterval(refreshInterval);
          }
        }, 3000);
        
        return () => clearInterval(refreshInterval);
      }
    }
  }, [user, tabValue, location.state?.fromCreation]);
  
  const fetchTasks = async () => {
    if (!user || !user.userId) return;
    
    setLoading(true);
    setError('');
    
    try {
      let url = `${config.API_URL}/tasks?userId=${user.userId}`;
      
      // Add status filter if not on 'all' tab
      if (tabValue !== 'all') {
        url += `&status=${tabValue}`;
      }
      
      const response = await axios.get(url);
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setError('Failed to fetch tasks. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  const handleOpenTaskDetails = (task) => {
    setSelectedTask(task);
    setOpenTaskDialog(true);
  };
  
  const handleCloseTaskDialog = () => {
    setOpenTaskDialog(false);
  };
  
  const handleRetryConfirmation = (taskId) => {
    const task = tasks.find(t => t.id === taskId);
    if (!task) return;
    
    setConfirmRetryDialog({
      open: true,
      taskId,
      status: task.status
    });
  };
  
  const handleCloseRetryConfirmation = () => {
    setConfirmRetryDialog({ open: false, taskId: null });
  };
  
  const handleConfirmRetry = async () => {
    const taskId = confirmRetryDialog.taskId;
    handleCloseRetryConfirmation();
    
    if (!taskId) return;
    
    // Execute the retry action
    await handleRetryTask(taskId);
  };
  
  const handleRetryTask = async (taskId) => {
    if (!taskId) return;
    
    setRetryingTask(taskId);
    
    try {
      const response = await axios.put(`${config.API_URL}/tasks/${taskId}/retry`);
      
      // Update the task in the local state
      setTasks(prevTasks => 
        prevTasks.map(task => 
          task.id === taskId 
            ? { ...task, status: 'pending', error: null } 
            : task
        )
      );
      
      // If task details dialog is open and showing this task, update it
      if (selectedTask && selectedTask.id === taskId) {
        setSelectedTask(prevTask => ({
          ...prevTask,
          status: 'pending',
          error: null
        }));
      }
      
      // Show success message
      setSnackbar({
        open: true,
        message: 'Task has been queued for retry',
        severity: 'success'
      });
      
    } catch (error) {
      console.error('Error retrying task:', error);
      
      // Show error message
      setSnackbar({
        open: true,
        message: error.response?.data?.message || 'Failed to retry task',
        severity: 'error'
      });
    } finally {
      setRetryingTask(null);
    }
  };
  
  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };
  
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'MMM d, yyyy h:mm a');
    } catch (e) {
      return dateString;
    }
  };
  
  return (
    <Layout>
      <Container>
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Task Manager
          </Typography>
          
          {successAlert.show && (
            <Alert 
              severity="success" 
              sx={{ 
                mb: 2,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircle sx={{ mr: 1 }} />
                <Typography variant="body1">{successAlert.message}</Typography>
              </Box>
            </Alert>
          )}
          
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{ flexGrow: 1 }}
            >
              <Tab label="All Tasks" value="all" />
              <Tab label="Pending" value="pending" />
              <Tab label="Running" value="running" />
              <Tab label="Completed" value="done" />
              <Tab label="Failed" value="failed" />
            </Tabs>
            
            <IconButton onClick={fetchTasks} disabled={loading}>
              <Refresh />
            </IconButton>
          </Box>
          
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress />
            </Box>
          ) : tasks.length === 0 ? (
            <Paper sx={{ p: 3, textAlign: 'center' }}>
              <Typography variant="body1">
                No tasks found.
              </Typography>
            </Paper>
          ) : (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Campaign Name</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasks
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((task) => (
                        <TableRow hover key={task.id}>
                          <TableCell>{task.id}</TableCell>
                          <TableCell>
                            {task.data && task.data.campaignName 
                              ? task.data.campaignName 
                              : 'Unknown Campaign'}
                          </TableCell>
                          <TableCell>{formatDate(task.createdAt)}</TableCell>
                          <TableCell>
                            <StatusBadge status={task.status} />
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Tooltip title="View Task Details">
                                <IconButton
                                  size="small"
                                  onClick={() => handleOpenTaskDetails(task)}
                                  sx={{ mr: 1 }}
                                >
                                  <InfoOutlined fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              
                              {(task.status === 'failed' || task.status === 'pending' || task.status === 'running') && (
                                <Tooltip title="Retry Task">
                                  <IconButton
                                    size="small"
                                    onClick={() => handleRetryConfirmation(task.id)}
                                    disabled={retryingTask === task.id}
                                    color="primary"
                                  >
                                    {retryingTask === task.id ? (
                                      <CircularProgress size={20} />
                                    ) : (
                                      <ReplayCircleFilled fontSize="small" />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={tasks.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </Box>
        
        {/* Task Details Dialog */}
        <Dialog
          open={openTaskDialog}
          onClose={handleCloseTaskDialog}
          fullWidth
          maxWidth="md"
        >
          {selectedTask && (
            <>
              <DialogTitle sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6">
                    Task Details (ID: {selectedTask.id})
                    <StatusBadge status={selectedTask.status} sx={{ ml: 2 }} />
                  </Typography>
                  
                  {(selectedTask.status === 'failed' || selectedTask.status === 'pending' || selectedTask.status === 'running') && (
                    <Button
                      variant="outlined"
                      startIcon={retryingTask === selectedTask.id ? <CircularProgress size={20} /> : <ReplayCircleFilled />}
                      onClick={() => handleRetryConfirmation(selectedTask.id)}
                      disabled={retryingTask === selectedTask.id}
                      color="primary"
                      size="small"
                    >
                      Retry Task
                    </Button>
                  )}
                </Box>
              </DialogTitle>
              <DialogContent dividers>
                <Typography variant="subtitle1" gutterBottom>
                  Campaign Information
                </Typography>
                <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
                  <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                    <Box>
                      <Typography variant="caption" color="text.secondary">Campaign Name</Typography>
                      <Typography variant="body1">{selectedTask.data?.campaignName || 'N/A'}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">Country</Typography>
                      <Typography variant="body1">{selectedTask.data?.country || 'N/A'}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">Device</Typography>
                      <Typography variant="body1">{selectedTask.data?.device || 'N/A'}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">Budget</Typography>
                      <Typography variant="body1">${selectedTask.data?.budget || 'N/A'}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">Created At</Typography>
                      <Typography variant="body1">{formatDate(selectedTask.createdAt)}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">Updated At</Typography>
                      <Typography variant="body1">{formatDate(selectedTask.updatedAt)}</Typography>
                    </Box>
                  </Box>
                </Paper>
                
                {selectedTask.status === 'failed' && selectedTask.error && (
                  <Alert severity="error" sx={{ mb: 3 }}>
                    <Typography variant="subtitle2">Error:</Typography>
                    <Typography variant="body2">{selectedTask.error}</Typography>
                  </Alert>
                )}
                
                {selectedTask.Campaigns && selectedTask.Campaigns.length > 0 && (
                  <>
                    <Typography variant="subtitle1" gutterBottom>
                      Associated Campaigns
                    </Typography>
                    <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Traffic Source</TableCell>
                            <TableCell>External ID</TableCell>
                            <TableCell>Account ID</TableCell>
                            <TableCell>URL</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedTask.Campaigns.map((campaign) => (
                            <TableRow key={campaign.id}>
                              <TableCell>{campaign.id}</TableCell>
                              <TableCell>{campaign.trafficSource || 'N/A'}</TableCell>
                              <TableCell>
                                {campaign.trafficSourceCampaignIds && 
                                 campaign.trafficSource &&
                                 campaign.trafficSourceCampaignIds[campaign.trafficSource]
                                 ? campaign.trafficSourceCampaignIds[campaign.trafficSource]
                                 : 'N/A'}
                              </TableCell>
                              <TableCell>{campaign.accountId || 'N/A'}</TableCell>
                              <TableCell>
                                <Link 
                                  href={campaign.url} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  sx={{
                                    maxWidth: '250px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    display: 'block'
                                  }}
                                >
                                  {campaign.url}
                                </Link>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
                
                <Typography variant="subtitle1" gutterBottom>
                  Task Data
                </Typography>
                <Paper 
                  variant="outlined" 
                  sx={{ 
                    p: 2, 
                    maxHeight: '200px', 
                    overflow: 'auto',
                    fontFamily: 'monospace',
                    fontSize: '0.875rem',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                  }}
                >
                  {JSON.stringify(selectedTask.data, null, 2)}
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseTaskDialog}>Close</Button>
              </DialogActions>
            </>
          )}
        </Dialog>
        
        {/* Confirmation Dialog for Retry */}
        <Dialog
          open={confirmRetryDialog.open}
          onClose={handleCloseRetryConfirmation}
        >
          <DialogTitle>Confirm Task Retry</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmRetryDialog.status === 'running' 
                ? 'This task is currently running. Retrying will reset its status to pending. It may cause duplicate operations if the task is still being processed. Are you sure you want to retry this task?'
                : 'Are you sure you want to retry this task? The task status will be reset to pending.'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRetryConfirmation} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmRetry} color="primary" variant="contained">
              Retry
            </Button>
          </DialogActions>
        </Dialog>
        
        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Layout>
  );
};

export default Tasks;
