import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import {Register} from "./components/Register";
import {Login} from "./components/Login";
import {Logout} from "./components/Logout";

import Creation from "./components/creation";
import PrivateWrapper from './PrivateWrapper';
import PublicRoute from './PublicRoute';
import Tasks from "./components/Tasks";

function RoutesComponent() {
    return (
        <Router>
            <Routes>
                <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />
                <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/create" element={<PrivateWrapper><Creation /></PrivateWrapper>} />
                <Route path="/clone/:id" element={<PrivateWrapper><Creation /></PrivateWrapper>} />
                <Route path="/tasks" element={<PrivateWrapper><Tasks /></PrivateWrapper>} />
                <Route path="/" element={<PrivateWrapper><Tasks /></PrivateWrapper>} />
                <Route path="/success" element={
                    <Navigate 
                        to="/" 
                        state={{ 
                            success: true, 
                            message: "Campaign creation successful!",
                            fromCreation: true,
                            timestamp: new Date().getTime()
                        }} 
                        replace 
                    />
                } />
                <Route path="/campaigns" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}

export default RoutesComponent;