// Configuration file to centralize API settings

// Use environment variables if available, fallback to development URL
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost';

// Log the API URL being used
console.log('Config: Using API URL:', API_URL, 'from env:', process.env.REACT_APP_API_URL);

const config = {
  API_URL
};

export default config; 