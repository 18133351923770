import React from 'react';
import './AdCard.css'; // Ensure this CSS file is linked correctly
import { Box, Typography, Button, Card, CardMedia, CardContent, Chip } from '@mui/material';

const AdCard = ({ headline, imageUrl, description, selected, onSelectCard }) => {
    return (
        <Card 
            onClick={onSelectCard}
            variant="outlined"
            sx={{ 
                position: 'relative',
                transition: 'all 0.3s ease',
                border: selected ? '2px solid #1976d2' : '1px solid #e0e0e0',
                boxShadow: selected ? '0 4px 8px rgba(25, 118, 210, 0.3)' : '0 2px 4px rgba(0,0,0,0.1)',
                borderRadius: '6px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                cursor: 'pointer',
                '&:hover': {
                    boxShadow: '0 6px 12px rgba(0,0,0,0.15)'
                }
            }}
        >
            {selected && (
                <Chip 
                    label="Selected" 
                    color="primary" 
                    size="small"
                    sx={{ 
                        position: 'absolute', 
                        top: 4, 
                        right: 4, 
                        zIndex: 5,
                        fontWeight: 'bold',
                        fontSize: '9px',
                        height: '18px',
                        '& .MuiChip-label': {
                            px: 1
                        }
                    }}
                />
            )}
            
            <CardMedia
                component="img"
                image={imageUrl}
                alt="Ad image"
                sx={{ 
                    height: 120,
                    objectFit: 'cover'
                }}
            />
            
            <CardContent sx={{ 
                p: 1, 
                flexGrow: 1, 
                display: 'flex', 
                flexDirection: 'column',
                '&:last-child': { 
                    pb: 1
                }
            }}>
                <Box 
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        mb: 0.5 
                    }}
                >
                    <Typography 
                        variant="caption" 
                        sx={{ 
                            color: '#888', 
                            fontSize: '8px', 
                            backgroundColor: '#f5f5f5',
                            px: 0.5,
                            borderRadius: 1
                        }}
                    >
                        Sponsored
                    </Typography>
                </Box>
                
                <Typography 
                    variant="subtitle2" 
                    sx={{ 
                        fontWeight: 'bold',
                        mb: 0.5,
                        lineHeight: 1.2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        fontSize: '12px'
                    }}
                >
                    {headline}
                </Typography>
                
                <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ 
                        mb: 0.5,
                        lineHeight: 1.2,
                        fontSize: '10px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        flexGrow: 1
                    }}
                >
                    {description}
                </Typography>
                
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space-between',
                    mt: 'auto'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box 
                            component="img" 
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/80/Wikipedia-logo-v2.svg/20px-Wikipedia-logo-v2.svg.png"
                            alt="Publisher logo"
                            sx={{ 
                                width: 12, 
                                height: 12, 
                                borderRadius: '50%', 
                                mr: 0.5 
                            }}
                        />
                        <Typography variant="caption" sx={{ color: '#666', fontSize: '9px' }}>
                            ad.example.com
                        </Typography>
                    </Box>
                    
                    <Button 
                        size="small" 
                        variant="outlined"
                        sx={{ 
                            textTransform: 'none', 
                            minWidth: 'auto', 
                            py: 0, 
                            px: 0.8, 
                            fontSize: '9px',
                            borderRadius: 5,
                            borderColor: '#ccc',
                            color: '#555',
                            height: '18px',
                            lineHeight: 1
                        }}
                    >
                        Learn More
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default AdCard;
