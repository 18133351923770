import React from 'react';
import { Box, Grid, Typography, Paper, Divider } from "@mui/material";
import AdCard from "./AdComponent";

const MatrixGridComponent = ({ headlines, images, description, setSelectedCards, selectedCards }) => {
    // Handler for selecting and deselecting cards
    const handleSelectCard = (card) => {
        // Check if the card is already selected
        const isAlreadySelected = selectedCards.some(selectedCard =>
            selectedCard.headline === card.headline && selectedCard.imageUrl === card.imageUrl
        );

        // If it's selected, remove it from the array, otherwise add it
        setSelectedCards(prevSelectedCards =>
            isAlreadySelected
                ? prevSelectedCards.filter(selectedCard =>
                    selectedCard.headline !== card.headline || selectedCard.imageUrl !== card.imageUrl
                )
                : [...prevSelectedCards, card]
        );
    };

    // Function to determine if a card is selected
    const isSelected = (headline, imageUrl) => {
        return selectedCards.some(card => card.headline === headline && card.imageUrl === imageUrl);
    };

    return (
        <Box sx={{ width: '100%' }}>
            {headlines.size === 0 && (
                <Typography variant="body2" sx={{ textAlign: 'center', py: 4, color: 'text.secondary' }}>
                    No headlines or images selected
                </Typography>
            )}
            
            <Grid container spacing={3}>
                {[...new Set(headlines)].map((headline, indexHeadline) => (
                    images.map((image, indexImage) => (
                        <Grid 
                            item 
                            xs={12} 
                            sm={6} 
                            md={4} 
                            lg={3}
                            key={`${indexHeadline}-${indexImage}`}
                            sx={{ display: 'flex', mb: 1 }}
                        >
                            <Box sx={{ 
                                width: '100%', 
                                height: '100%', 
                                p: 0.5 // Add padding inside the Box
                            }}>
                                <AdCard
                                    headline={headline}
                                    imageUrl={image}
                                    description={description}
                                    selected={isSelected(headline, image)}
                                    onSelectCard={() => handleSelectCard({
                                        headline: headline,
                                        imageUrl: image,
                                        description: description
                                    })}
                                />
                            </Box>
                        </Grid>
                    ))
                ))}
            </Grid>
        </Box>
    );
};

export default MatrixGridComponent;
