import axios from 'axios';
import config from '../config';

// Create axios instance with common configuration
const axiosInstance = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Log all requests for debugging
axiosInstance.interceptors.request.use(request => {
  console.log('Starting Request:', request.url);
  return request;
});

// Log all responses for debugging
axiosInstance.interceptors.response.use(
  response => {
    console.log('Response:', response.status);
    return response;
  },
  error => {
    console.error('Response Error:', error);
    return Promise.reject(error);
  }
);

export default axiosInstance; 