import React from 'react';
import {
    Box,
    Grid,
    Typography,
    Paper,
    Divider,
    Chip
} from '@mui/material';

/**
 * A component to display a preview of campaign details before creation
 */
const CampaignPreview = ({
    campaignTitle,
    campaignBudget,
    campaignType,
    campaignDevice,
    countries,
    keyword,
    description,
    feeds,
    selectedFeed,
    selectedCards
}) => {
    // Find the selected feed name
    const selectedFeedName = feeds.find(f => f.id === selectedFeed)?.name || 'None';
    
    // Format device text for display
    const deviceText = campaignDevice.includes('PHON') && campaignDevice.includes('DESK') 
        ? 'Smartphone & Desktop' 
        : campaignDevice.includes('PHON') 
            ? 'Smartphone' 
            : 'Desktop';
    
    // Calculate total ads to create
    const totalAds = selectedCards.length * countries.length * campaignDevice.length;
    
    return (
        <Paper 
            elevation={0} 
            sx={{ 
                mb: 3, 
                borderRadius: 1, 
                border: '1px solid #eee',
                overflow: 'hidden'
            }}
        >
            <Grid container>
                {/* Campaign Details Section */}
                <Grid item xs={12} md={6} sx={{ borderRight: { md: '1px solid #eee' } }}>
                    <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2" color="primary" sx={{ fontSize: '0.75rem', mb: 1 }}>
                            Campaign Details
                        </Typography>
                        <Divider sx={{ mb: 1.5 }} />
                        
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.75 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                    Title:
                                </Typography>
                                <Typography variant="body2" fontWeight="medium" sx={{ fontSize: '0.75rem', maxWidth: '60%', textAlign: 'right' }}>
                                    {campaignTitle || '(not set)'}
                                </Typography>
                            </Box>
                            
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                    Budget:
                                </Typography>
                                <Typography variant="body2" fontWeight="medium" sx={{ fontSize: '0.75rem' }}>
                                    ${campaignBudget}
                                </Typography>
                            </Box>
                            
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                    Type:
                                </Typography>
                                <Chip 
                                    size="small"
                                    label={campaignType} 
                                    color={campaignType === 'RSOC' ? 'primary' : 'secondary'}
                                    variant="outlined"
                                    sx={{ height: 20, '& .MuiChip-label': { fontSize: '0.65rem', px: 1 } }}
                                />
                            </Box>
                            
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                    Device:
                                </Typography>
                                <Typography variant="body2" fontWeight="medium" sx={{ fontSize: '0.75rem' }}>
                                    {deviceText}
                                </Typography>
                            </Box>
                            
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem', mt: 0.5 }}>
                                    Countries:
                                </Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', gap: 0.5, maxWidth: '70%' }}>
                                    {countries.map(country => (
                                        <Chip 
                                            key={country}
                                            size="small"
                                            label={country} 
                                            color="default"
                                            variant="outlined"
                                            sx={{ 
                                                fontSize: '0.65rem', 
                                                height: 20, 
                                                '& .MuiChip-label': { 
                                                    px: 1 
                                                } 
                                            }}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                
                {/* Ad Details Section */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ p: 2, bgcolor: '#fafafa' }}>
                        <Typography variant="subtitle2" color="primary" sx={{ fontSize: '0.75rem', mb: 1 }}>
                            Ad Details
                        </Typography>
                        <Divider sx={{ mb: 1.5 }} />
                        
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.75 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                    Keyword:
                                </Typography>
                                <Typography variant="body2" fontWeight="medium" sx={{ fontSize: '0.75rem', maxWidth: '60%', textAlign: 'right' }}>
                                    {keyword || '(not set)'}
                                </Typography>
                            </Box>
                            
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                    Description:
                                </Typography>
                                <Typography variant="body2" fontWeight="medium" sx={{ fontSize: '0.75rem', width: '60%', textAlign: 'right', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {description || '(not set)'}
                                </Typography>
                            </Box>
                            
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                    Feed:
                                </Typography>
                                <Typography variant="body2" fontWeight="medium" sx={{ fontSize: '0.75rem', maxWidth: '60%', textAlign: 'right', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {selectedFeedName}
                                </Typography>
                            </Box>
                            
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                    Selected Ads:
                                </Typography>
                                <Chip 
                                    size="small"
                                    label={selectedCards.length} 
                                    color="success"
                                    sx={{ height: 20, '& .MuiChip-label': { fontSize: '0.7rem' } }}
                                />
                            </Box>
                            
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                    Total Ads:
                                </Typography>
                                <Typography variant="body2" fontWeight="bold" color="primary.main" sx={{ fontSize: '0.75rem' }}>
                                    {totalAds}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CampaignPreview;
